import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import {Apis} from "../../../util/Apis";
import {Controller, useForm} from "react-hook-form";
import useEditModal from "../../../hooks/useEditModal";
import ConversationMatch from "../ConversationMatch";
import {ButtonBlock} from "../../Server/ProgrammingList";
import {Button, FlexContainer, PageSection, Table} from "@wargostec/react-components";
import {DateTime} from "luxon";
import ConversationBody from "../ConversationBody";
import PageContainerFull from "../../../components/PageContainerFull";
import PageTitle from "../../../components/Layaout/PageTitle";
import {Col, Row} from "react-bootstrap";
import SelectSalas from "../../../Selects/SelectSalas";
import {TfiReload} from "react-icons/tfi";
import {GrClearOption} from "react-icons/gr";
import {ContainerLoading} from "../../SalasList";
import {Loading} from "../../../components/forms/Loading";
import GenericModal2 from "../../../hooks/GenericModal2";
import {StatusTag} from "../ConversationList";
import ConversationSelectGeneric from "../ConversationSelectGeneric";

const TicketList = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const salaIdParam = searchParams.get('salaId') || '';
  const [params, setParams] = useState({salaId: salaIdParam, ticketEnabled: true});
  const {loading, data, fetchData, error} = useFetch(Apis.SUPPORT + '/conversation', [], params);
  const {control} = useForm({defaultValues: {salaId: salaIdParam}});

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <ConversationMatch/>
  })

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  useEffect(() => {
    if (params) {
      fetchData()
    }
  }, [params])

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const actionValue = useCallback(props => <>{
    <ButtonBlock>
      <Button text={'Vincular'} size={'xs'} variant={'warning'} onClick={() => editAction(props.renderValue())}/>
      <Button text={'Conversación'} size={'xs'} variant={'success'}
              onClick={() => conversationBodyView(props.renderValue())}/>
    </ButtonBlock>
  }</>, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const nameValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{props.renderValue()}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const openValue = useCallback(props => <>
      {
        <>
          <StatusTag isClosed={props.renderValue()}>
            {props.renderValue() ? ' Abierto' : ' Cerrado'}
          </StatusTag>
        </>
      }
    </>
    , []);

  const {wrapper: wrapper2, refresh: refresh2, editAction: conversationBodyView} = useEditModal({
    editComponent: <ConversationBody/>
  })

  const resolvedValue = useCallback(props => {
    const resolvedValue = props.renderValue() ? "SI" : "NO";
    const mood = props.row.original.summaryAi?.mood ?? "---";
    return (
        <>
          <span>{resolvedValue + " / " + mood}</span>
        </>
    );
  }, []);

  const typeOptions = [
    {id: '', name: 'Seleccione...'},
    {id: 'BUG', name: 'BUG'},
    {id: 'CONSULTA', name: 'CONSULTA'},
    {id: 'CONFIGURACION', name: 'CONFIGURACION'},
    {id: 'OTRO', name: 'OTRO'},
  ]

  const typeValue = useCallback((props) => (
    <>
      <div>
        <ConversationSelectGeneric intercomId={props.row.original.intercomId} value={props.renderValue()} options={typeOptions} endpoint={'type'}/>
      </div>
    </>
  ), []);

  const areaOptions = [
    {id: '', name: 'Seleccione...'},
    {id: 'FACTURACION', name: 'FACTURACION'},
    {id: 'LOGISTICA', name: 'LOGISTICA'},
    {id: 'MAQUINAS', name: 'MAQUINAS'},
    {id: 'USUARIOS', name: 'USUARIOS'},
    {id: 'CAJA', name: 'CAJA'},
    {id: 'CRM', name: 'CRM'},
  ]

  const areaValue = useCallback((props) => (
    <>
      <div>
        <ConversationSelectGeneric intercomId={props.row.original.intercomId} value={props.renderValue()} options={areaOptions} endpoint={'area'}/>
      </div>
    </>
  ), []);

  const columns = useMemo(
    () => [
      {
        header: 'Origen',
        accessorKey: 'type',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'SalaID',
        accessorKey: 'salaId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Autor',
        accessorKey: 'author.name',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: nameValue
      },
      {
        header: 'Email',
        accessorKey: 'author.email',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: nameValue
      },
      {
        header: 'Telefono',
        accessorKey: 'author.cellphone',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Titulo',
        accessorKey: 'ticket.title',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Tipo',
        accessorKey: 'ticket.ticketType',
        cell: nameValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Descripcion',
        accessorKey: 'ticket.ticketTypeDescription',
        cell: nameValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Resumen (IA)',
        accessorKey: 'summaryAi.title',
        cell: nameValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Resuelto (IA)',
        accessorKey: 'summaryAi.resolved',
        cell: resolvedValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Tipo',
        accessorKey: 'kind',
        cell: typeValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Area',
        accessorKey: 'area',
        cell: areaValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Creado',
        accessorKey: 'createdAt',
        cell: createdValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Actualizado',
        accessorKey: 'lastActivity',
        cell: createdValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Estado',
        accessorKey: 'open',
        cell: openValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Fecha (cierre)',
        accessorKey: 'lastCloseAt',
        cell: createdValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Acciones',
        accessorKey: 'intercomId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionValue
      },
    ]
    , []
  )

  const reloadData = () => {
    fetchData()
  }

  const clear = () => {
    getData({})
  }

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Tickets'}/>
        <FlexContainer column gap='20px'>
          <Row>
            <Col>
              <div style={{width: '300px'}}>
                <Controller
                  name="salaId"
                  control={control}
                  render={({field}) => (
                    <SelectSalas
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        getData({salaId: value});
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              <Button style={{paddingRight: "10px"}} variant={'success'} onClick={reloadData}
                      icon={<TfiReload/>}/>
            </Col>
            <Col>
              <Button variant={'primary'} onClick={clear} icon={<GrClearOption/>}/>
            </Col>
          </Row>
        </FlexContainer>
      </PageSection>

      <PageSection>
        <div>
          <>
            {
              loading ?
                <ContainerLoading>
                  <Loading variant='secondary'/>
                </ContainerLoading>
                :
                <>
                  {
                    data && data.data &&
                    <Table columns={columns} data={data.data} pageSize={100} searchable={true}/>
                  }
                </>
            }
          </>
        </div>
      </PageSection>
      <GenericModal2 {...wrapper1}/>
      <GenericModal2 {...wrapper2}/>

    </PageContainerFull>

  );
};

export default TicketList;
