import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PageContainerFull from "../../components/PageContainerFull";
import {Button, FlexContainer, PageSection, Table} from "@wargostec/react-components";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import useFetch from "../../hooks/useFetch";
import useEditModal from "../../hooks/useEditModal";
import GenericModal2 from "../../hooks/GenericModal2";
import {DateTime} from "luxon";
import ConversationMatch from "./ConversationMatch";
import {Apis} from "../../util/Apis";
import {Col, Dropdown, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import SelectSalas from "../../Selects/SelectSalas";
import {TfiReload} from "react-icons/tfi";
import {GrClearOption} from "react-icons/gr";
import {useNavigate, useSearchParams} from "react-router-dom";
import styled from "styled-components";
import CardOverview, {CardOverviewContainer, FlexLayout} from "../../components/CardOverview";
import {FaArrowsAltH, FaRegListAlt, FaRegSun} from "react-icons/fa";
import ConversationBody from "./ConversationBody";
import {FaTicket} from "react-icons/fa6";
import {SwitchToggle} from "../../components/forms/SwitchToggle";
import {BiSolidConversation} from "react-icons/bi";
import {IoTicketSharp} from "react-icons/io5";
import ConversationSelectGeneric from "./ConversationSelectGeneric";

export const StatusTag = styled.span`
    background-color: ${(props) => (props.isClosed ? '#4caf50' : '#fce91c')};
    color: black;
    font-size: 13px;
    font-weight: bold;
    padding: 8px 15px;
    border-radius: 12px;
    margin-left: 10px;
    margin-top: -25px;
`;

const ConversationList = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const salaIdParam = searchParams.get('salaId') || '';
  const [params, setParams] = useState({salaId: salaIdParam});
  const {loading, data, fetchData} = useFetch(Apis.SUPPORT + '/conversation', [], params);
  const {control} = useForm({defaultValues: {salaId: salaIdParam}});
  const [ticketEnabled, setTicketEnabled] = useState(false);

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <ConversationMatch/>
  })

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  useEffect(() => {
    if (params) {
      fetchData()
    }
  }, [params])

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const navigate = useNavigate();

  function goTicketList(salaId) {
    navigate("/ticket-list?salaId=" + salaId);
  }

  const actionValue = useCallback(props =>
          <FlexLayout justifyContent="center">
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-custom-1" size="sm">
                <FaRegSun />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                    onClick={() => editAction(props.renderValue())}><FaArrowsAltH /> Vincular
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => conversationBodyView(props.renderValue())}><BiSolidConversation /> Conversación
                </Dropdown.Item>
                <Dropdown.Item onClick={() => goTicketList(props.row.original.salaId)}><IoTicketSharp />
                  Tickets
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </FlexLayout>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      , []);

  const createdValue = useCallback(props => <>
        {
          <div>
            {props.renderValue() ?
                <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
                <span>---</span>}
          </div>
        }
      </>
      , []);

  const nameValue = useCallback(props => <>
        {
          <div>
            {props.renderValue() ?
                <span>{props.renderValue()}</span> :
                <span>---</span>}
          </div>
        }
      </>
      , []);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.round(seconds % 60);

    return `${days > 0 ? `${days}d ` : ""}${hours}h ${minutes}m ${secs}s`;
  };

  const durationValue = useCallback((props) => (
      <>
        <div>
          {props.renderValue() ? (
              <span>{formatTime(props.renderValue())}</span>
          ) : (
              <span>---</span>
          )}
        </div>
      </>
  ), []);

  const divValue = useCallback((props) => (
      <div
          dangerouslySetInnerHTML={{
            __html: props.renderValue() ? props.renderValue() : '<span>---</span>',
          }}
      />
  ), []);

  const resolvedValue = useCallback(props => {
    const resolvedValue = props.renderValue() ? "SI" : "NO";
    const mood = props.row.original.summaryAi?.mood ?? "---";
    return (
        <>
          <span>{resolvedValue + " / " + mood}</span>
        </>
    );
  }, []);

  const typeOptions = [
    {id: '', name: 'Seleccione...'},
    {id: 'BUG', name: 'BUG'},
    {id: 'CONSULTA', name: 'CONSULTA'},
    {id: 'CONFIGURACION', name: 'CONFIGURACION'},
    {id: 'PROBLEMA EXTERNO', name: 'PROBLEMA EXTERNO'},
    {id: 'REQUERIMIENTO', name: 'REQUERIMIENTO'},
    {id: 'OTRO', name: 'OTRO'},
  ]

  const typeValue = useCallback((props) => (
    <>
      <div>
          <ConversationSelectGeneric intercomId={props.row.original.intercomId} value={props.renderValue()} options={typeOptions} endpoint={'type'}/>
      </div>
    </>
  ), []);

  const areaOptions = [
    {id: '', name: 'Seleccione...'},
    {id: 'FACTURACION', name: 'FACTURACION'},
    {id: 'LOGISTICA', name: 'LOGISTICA'},
    {id: 'MAQUINAS', name: 'MAQUINAS'},
    {id: 'USUARIOS', name: 'USUARIOS'},
    {id: 'CAJA', name: 'CAJA'},
    {id: 'CRM', name: 'CRM'},
  ]

  const areaValue = useCallback((props) => (
    <>
      <div>
          <ConversationSelectGeneric intercomId={props.row.original.intercomId} value={props.renderValue()} options={areaOptions} endpoint={'area'}/>
      </div>
    </>
  ), []);

  const ticketValue = useCallback((props) => (
    <>
      <div>
        {props.renderValue() ? (
          <span><FaTicket size={20}/></span>
        ) : (
          <span></span>
        )}
      </div>
    </>
  ), []);

  const openValue = useCallback(props => <>
        {
          <>
            <StatusTag isClosed={props.renderValue()}>
              {props.renderValue() ? ' Abierto' : ' Cerrado'}
            </StatusTag>
          </>
        }
      </>
      , []);

  const {wrapper: wrapper2, refresh: refresh2, editAction: conversationBodyView} = useEditModal({
    editComponent: <ConversationBody/>
  })

  const columns = useMemo(
      () => [
        {
          header: 'Origen',
          accessorKey: 'type',
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          }
        },
        {
          header: 'SalaID',
          accessorKey: 'salaId',
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          }
        },
        {
          header: 'Autor',
          accessorKey: 'author.name',
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
          cell: nameValue
        },
        {
          header: 'Email',
          accessorKey: 'author.email',
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          },
          cell: nameValue
        },
        {
          header: 'Telefono',
          accessorKey: 'author.cellphone',
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          }
        },
        {
          header: 'Context',
          accessorKey: 'subject',
          cell: divValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        {
          header: 'Resumen (IA)',
          accessorKey: 'summaryAi.title',
          cell: nameValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        {
          header: 'Resuelto (IA)',
          accessorKey: 'summaryAi.resolved',
          cell: resolvedValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        {
          header: 'Tipo',
          accessorKey: 'kind',
          cell: typeValue,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          }
        },
        {
          header: 'Area',
          accessorKey: 'area',
          cell: areaValue,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          }
        },
        {
          header: 'Ticket',
          accessorKey: 'ticketEnabled',
          cell: ticketValue,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          }
        },
        {
          header: 'Creado',
          accessorKey: 'createdAt',
          cell: createdValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        {
          header: '--->',
          accessorKey: 'timeToAdminReply',
          cell: durationValue,
          sortType: 'basic',
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        {
          header: 'Primera respuesta (Admin)',
          accessorKey: 'firstAdminReplyAt',
          cell: createdValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        // {
        //   header: 'Actualizado',
        //   accessorKey: 'lastActivity',
        //   cell: createdValue,
        //   meta: {
        //     headerClassName: 'text-left',
        //     columnClassName: 'text-left',
        //   }
        // },
        {
          header: 'Estado',
          accessorKey: 'open',
          cell: openValue,
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          }
        },
        {
          header: 'Duracion',
          accessorKey: 'duration',
          cell: durationValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        {
          header: 'Fecha (cierre)',
          accessorKey: 'lastCloseAt',
          cell: createdValue,
          meta: {
            headerClassName: 'text-left',
            columnClassName: 'text-left',
          }
        },
        {
          header: 'Acciones',
          accessorKey: 'intercomId',
          meta: {
            headerClassName: 'text-center',
            columnClassName: 'text-center',
          },
          cell: actionValue
        },
      ]
      , []
  )

  const reloadData = () => {
    fetchData()
  }

  const clear = () => {
    getData({})
    setTicketEnabled(false)
  }

  return (
      <PageContainerFull>
        <PageSection>
          <PageTitle title={'Chats'}/>
          <FlexContainer column gap='20px'>
            <Row>
              <Col>
                <div style={{width: '300px'}}>
                  <Controller
                      name="salaId"
                      control={control}
                      render={({field}) => (
                          <SelectSalas
                              {...field}
                              onChange={(value) => {
                                field.onChange(value);
                                getData({salaId: value});
                              }}
                          />
                      )}
                  />
                </div>
              </Col>
              <Col>
                <div style={{paddingTop: '10px'}}>
                  <SwitchToggle label={'Tickets'} onChange={() => setTicketEnabled(!ticketEnabled)} checked={ticketEnabled}
                                onClick={() => getData({ticketEnabled: !ticketEnabled})}/>
                </div>
              </Col>
              <Col>
                <Button style={{paddingRight: "10px"}} variant={'success'} onClick={reloadData}
                        icon={<TfiReload/>}/>
              </Col>
              <Col>
                <Button variant={'primary'} onClick={clear} icon={<GrClearOption/>}/>
              </Col>
            </Row>
          </FlexContainer>
        </PageSection>

        <PageSection>
          <CardOverviewContainer gridColumns='repeat(4, 1fr)'>
            <CardOverview
                title={formatTime(data && data.averageDuration)}
                subtitle='Respuest promedio'
                icon={<FaRegListAlt size={20}/>}
            />
          </CardOverviewContainer>

        </PageSection>

        <PageSection>
          <div>
            <>
              {
                loading ?
                    <ContainerLoading>
                      <Loading variant='secondary'/>
                    </ContainerLoading>
                    :
                    <>
                      {
                        data && data.data &&
                          <Table columns={columns} data={data.data} pageSize={100} searchable={true} />
                      }
                    </>
              }
            </>
          </div>
        </PageSection>
        <GenericModal2 {...wrapper1}/>
        <GenericModal2 {...wrapper2}/>

      </PageContainerFull>
  );
};
export default ConversationList;
