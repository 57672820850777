import React, {useState} from 'react';
import {SelectV2} from "../../components/forms/FormEl";
import axios from "axios";
import {Apis} from "../../util/Apis";
import {errorToast, successToast} from "../../components/Layaout/ToastStyles";

const ConversationSelectGeneric = ({intercomId, value, options, endpoint}) => {
  const [field, setField] = useState(value)

  const changeRole = (value) => {
    setField(value)
    axios.post(Apis.SUPPORT + `/conversation/${endpoint}/` + intercomId, {value: value})
      .then(() => {
        successToast("Operación exitosa")
      })
      .catch(err => {
        console.log('hubo un error')
        errorToast(err.response.data.message, 6000)
      });
  }

  return (
    <SelectV2 style={{minWidth: '140px'}} onChange={(e) => changeRole(e.target.value)} value={field || 0}
              options={options}/>
  )
}

export default ConversationSelectGeneric;
