import React, {useEffect} from 'react';
import {Apis} from "../../util/Apis";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import PageSection from "../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import SelectSalas from "../../Selects/SelectSalas";
import {Input} from "@wargostec/react-components";

const ConversationMatch = ({id, setAction, setTitle}) => {

  function update(params) {
    return axios.post(Apis.SUPPORT + '/conversation/link-sala/' + id, params);
  }

  useEffect(() => {
    setTitle('Adjuntar sala')
  }, []);

  const {handleSubmit, control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(update))
  }, [])

  return (
      <div>
        <div>
          <PageSection>
            <Row>
              <Col xs={12}>
                <label>Id</label>
                <Input type="number" value={id} disabled={true}/>
              </Col>
              <Col>
                <FormGroup>
                  <label>Sala</label>
                  <Controller
                      name="salaId"
                      control={control}
                      render={({field}) => <SelectSalas {...field} />}
                  />
                </FormGroup>
              </Col>
            </Row>
          </PageSection>
        </div>
      </div>
  );
};

export default ConversationMatch;