import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import styled from "styled-components";

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px;
`;

const Message = styled.div`
    max-width: 70%;
    padding: 12px 16px;
    border-radius: 16px;
    color: #fff;
    background: ${({ isUser }) => (isUser ? "#25D366" : "#495057")};
    align-self: ${({ isUser }) => (isUser ? "flex-start" : "flex-end")};
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    p {
        margin: 0;
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        display: block;
    }
`;

const ConversationBody = ({id, setAction, setTitle})  => {

  const {loading, data} = useFetch(Apis.SUPPORT + `/conversation/body/` + id);

  useEffect(() => {
    setTitle('Conversación');
  }, []);

  return (
      <>
        {!loading && data && (
            <ChatContainer>
              {data
                  .filter((msg) => msg.body !== null)
                  .map((msg, index) => (
                      <Message key={index} isUser={msg.emitter === "user"}>
                        <div dangerouslySetInnerHTML={{ __html: msg.body }} />
                      </Message>
                  ))}
            </ChatContainer>
        )}
      </>
  );
};

export default ConversationBody;